@import "../../../index.module";

.step-container {
  width: 96%;
  height: 705px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 16px;
}

.step-header {
  width: 100%;
  position: relative;
  padding: 17px 0;
  background-color: #343e48;
  -webkit-box-shadow: 0 2px 4px 0 rgba(189, 144, 144, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 25px;
    cursor: pointer;
  }

  i {
    width: 18px;
    height: 18px;
    margin: 0px 11px;
    display: inline-block;
    background-position: 0;
    &.back-icon {
      background: url("../../../assets/icons/back.png") no-repeat;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}
.button-container {
  button {
    margin: 10px;
  }
}
.button-container {
  display: inline-block;
  float: right;
}
.step-rightside {
  height: 380px;
  font-size: 15px;
  padding-top: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  overflow-y: auto;
  overflow-x: hidden;
}
.step-rightside-speed-alert {
  @extend .step-rightside;
  height: 100%;
  padding-top: 0;
}
.step-req {
  margin-left: 20px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
}
.step-content {
  width: 96%;
  height: 463px;
  margin: 20px;
}
.divider-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}
.vertical-divider {
  height: 463px;
  padding: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.vertical-divider-speed-alert {
  @extend .vertical-divider;
  padding: 0px 20px 20px;
}

.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-5ths {
  width: 20%;
  float: left;
}
.alerttype {
  font-weight: bold;
  padding: 5px !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-md-5ths {
    width: 100%;
    float: left;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-md-5ths {
    width: 50%;
    float: left;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .col-md-5ths {
      width: 25%;
      float: left;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }
}
// to remove box outline of back in alterstepper
.no-focus:focus {
  outline: none;
}
// opacity of text  in alterstepper
.text-op {
  opacity: 0.35;
  filter: Alpha(opacity=35);
}
// paddding between text in  in altestepper
.div-pad {
  padding-top: 25px;
}
// width of text box in alterstepper
.text-wid {
  width: 328px;
}
.text-mid-wid {
  width: 90px;
}
.txt-pad {
  padding-top: 16px;
}
.top-pad {
  padding-top: 5px;
}
// left paddding to textfield in  in fuel alert altestepper
.left-pad {
  padding-left: 38px;
}
.in-pad {
  padding-top: 28px;
  // align-self: auto;
}
.inn-pad {
  padding-top: 10px;
}
.fuel-alertradio label {
  margin-left: 16px;
  flex-direction: unset;
}
// for spacing b/w chips
.chip-spacing{
  margin: 10px;
}

// Alert-Recipient styles
.searchInputField{
  width: 27vw;
}
.comments-field{
  width: 33vw;
}
.Recipient-chip-container{
  height: 318px;
  overflow-x: hidden;
  overflow-y: auto;
}

// width of droppdown in alertScheduler
.droppdown-wid {
  width: 200px;
}
// height for time dropdown in Alert Schedule
// TODO: Find a better way to handle this.
div[role="document"] {
  ul[role="listbox"] {
    max-height: 250px;
  }
}
.select-padding{
  margin-left: 34px;
}
.selectb-padding{
  margin-left: 63px;
  margin-top: 10px;
}
.in-padd {
  padding-top: 10px;
}
// for spacing b/w chips
.chip-spacing{
  margin: 10px;
}
.chip-container{
  height: 216px;
  overflow-y: auto;
  overflow-x: hidden;
}
.schedule-container{
  margin-top:20px;
}

// Alert-Recipient styles
.searchInputField{
  width: 27vw;
}
.addButtonDiv{
  margin-left: 62px;
}
.commentsField{
  width: 33vw;
}
.unit-Name{
  display: inline-block ;
  padding-top: 20px ;
}
.unit-mid-name{
  display: inline-block ;
  padding-top: 20px;
  padding-left: 6px;
  padding-right: 30px;
}
.unit-Name-style{
  display: inline-block ;
}

.unit-Name-space {
  padding-top: 26px;
  padding-left: 8px;
}
.form-helper-text {
  color: red;
  display: inline;
  padding-left: 16px;
  padding-top: 24px;
}
// Alert-landmark styles
.select-Input-Field{
  width: 328px;
}
.load-pos{
  display: inline-block;
}
.landmark-selection{
  min-width: 85% !important;
  max-width: 360px;
}
// width of text box in alterstepper
.text-widfuel {
  width: 300px;
}

.email-container {
  width: 100%;
}

.alertSchedule-inputlabel {
  left:-14px;
  top: 8px;
}

.imageList {
  margin: 0px; 
  overflow-y: hidden;
}

.imageListItem {
  height: 467px !important;
}

.iconBtn {
  margin-bottom: -1px;
  margin-left: -3px;
  padding: 0 5px 4px;
}